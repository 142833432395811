.footer {
	margin-top: -60px;
	position: relative;
	&__inner {
		padding: 30px 0px;
		z-index: 200;
		position: relative;
		ul {
			list-style: none;
			padding: 0px;
			margin: 0px;
			margin-top: 5px;
			li {
				display: inline-block;
				margin-right: 30px;
				&.socials {
					float: right;
				}
				&.logo {
					margin-right: 40px;
				}
				a {
					color: @text-color;
				}
				@media (max-width: @screen-xs-max) {
					margin-right: 10px;
				}
			}
		}
	}
	&:before {
		content: " ";
		background-image: url('../images/pata_bkg.png');
		background-repeat: no-repeat;
		background-position: top center;
		background-size: cover;
		bottom: 0;
		left: 0;
		right: 0;
		position: absolute;
		height: 150px;
		z-index: 90;
	}
}