@import 'plugins.less';
@import "fonts.less";
@import "variables.less";

@import "../images/svg/svg.css";
@import "./bootstrap/bootstrap.less";

@import "./components/_header.less";
@import "./components/_footer.less";
@import "./components/_parallax.less";
@import "./components/_content.less";
@import "./components/_list.less";

.vertical-align {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
}

.vertical-align-bottom {
	position: absolute;
	top: 80%;
	transform: translateY(-80%);
}

.resetoffset {
	margin:0px;
	padding:0px;
}

.verticalcentered(){
	.flex-display(flex);
	.justify-content(center);
	.align-content(center);
	.flex-direction(column);
}

.navbar-toggle {
	margin: 0px;
	padding: 0px;
	font-size: 14px;
	color: @brand-third;
	margin-top: 4px;
	.icon-bar {
		background-color: @brand-third;
	}
}

.eu-cookies {
	display: none;
	position: fixed;
	left: 0;
	bottom: 0;
	width: 100%;
	color: #fff;
	padding: 10px;
	background-color: @brand-primary;
	z-index: 99999;
	font-size:13px;
	a {
		color: #fff;
	}

	button {
		background: #000;
		border:0px;
		color: white;	
	}
}

a {
	cursor: pointer !important;
	&:hover {
		text-decoration: none;
	}
}

.section-40 {
	padding-top:40px;
	padding-bottom:40px;
}

.section-120 {
	padding-top: 120px;
	padding-bottom: 120px;
}

.section-60 {
	padding-top: 60px;
	padding-bottom: 60px;
	@media (max-width: @screen-sm-max) {
		padding-top:0px;
		padding-bottom:0px;
		&.sm-60{
			padding-top:60px;
			padding-bottom:60px;
		}
	}
	&.bottom-0 {
		padding-bottom: 0px;
	}
	&.top-0 {
		padding-top: 0px;
	}
}

.section-30 {
	padding-top: 30px;
	padding-bottom: 30px;
	@media (max-width: @screen-sm-max) {
		&.sm-30{
			padding-top:30px;
			padding-bottom:30px;
		}
	}
	&.bottom-0 {
		padding-bottom: 0px;
	}
	&.top-0 {
		padding-top: 0px;
	}
}

.container {
	@media (max-width: @screen-sm-max) {
		&--sm-nopadding {
			.row {
				> div {
					padding:0px;
				}
			}
		}
	}
}

.container-fluid {
	&.no-padding {
		padding-left: 0px;
		padding-right: 0px;
		.row {
			margin-left: 0px;
			margin-right: 0px;
			>div {
				padding-left: 0px;
				padding-right: 0px;
			}
		}
	}
}

.navbar-collapse {
	box-shadow: none;
}

.specialBtn {
	padding: 10px 5px;
	border: 0px;
	border-bottom: 1px solid @brand-primary;
	font-weight: 600;
	transition: .5s;
	i.fa {
		margin-left: 25px;
	}
	&:hover {
		border-bottom: 1px solid @brand-primary;
	}
	&--primary {
		border-bottom: 0px;
		background-color: @brand-primary;
		color: @white;
		padding: 10px 25px;
		&:hover {
			border-bottom: 0px;
			background-color: @brand-second;
			color: @white;
		}
	}
	&--second {
		border-bottom: 0px;
		border: 1px solid @brand-second;
		padding: 10px 25px;
	}
	&--uppercase {
		font-size: @font-size-base - 2px;
		text-transform: uppercase;
		&:hover {
			background-color: @brand-second;
			border-bottom: 1px solid @brand-second;
		}
	}
	&--third {
		border-bottom: 0px;
		background-color: @brand-third;
		color: @white;
		padding: 10px 25px;
		&:hover {
			border-bottom: 0px;
		}
	}
}

ol {
	margin:0px;
	padding:0px;
	list-style: none;
	counter-reset: item;
	li {
		counter-increment: item;
		margin-bottom: 15px;
	}
	li:before {
		margin-right: 10px;
		content: counter(item);
		background: @brand-third;
		border-radius: 100%;
		color: @white;
		width: 25px;
		height: 25px;
		text-align: center;
		display: inline-block;
	}
}

.text-bold {
	font-weight: bold;
}

.text-second {
	color: @brand-second !important;
}

.m0 {margin:0px !important;}
.m5 {margin:5px !important;}
.m10 {margin:10px !important;}
.m15 {margin:15px !important;}
.m20 {margin:20px !important;}
.m25 {margin:25px !important;}

.mt0 {margin-top:0px !important;}
.mt5 {margin-top:5px !important;}
.mt10 {margin-top:10px !important;}
.mt15 {margin-top:15px !important;}
.mt20 {margin-top:20px !important;}
.mt25 {margin-top:25px !important;}

.mb0 {margin-bottom:0px !important;}
.mb5 {margin-bottom:5px !important;}
.mb10 {margin-bottom:10px !important;}
.mb15 {margin-bottom:15px !important;}
.mb20 {margin-bottom:20px !important;}
.mb25 {margin-bottom:25px !important;}

.p0 {padding:0px !important;}
.p5 {padding:5px !important;}
.p10 {padding:10px !important;}
.p15 {padding:15px !important;}
.p20 {padding:20px !important;}
.p25 {padding:25px !important;}

.pt0 {padding-top:0px !important;}
.pt5 {padding-top:5px !important;}
.pt10 {padding-top:10px !important;}
.pt15 {padding-top:15px !important;}
.pt20 {padding-top:20px !important;}
.pt25 {padding-top:25px !important;}

.pb0 {padding-bottom:0px !important;}
.pb5 {padding-bottom:5px !important;}
.pb10 {padding-bottom:10px !important;}
.pb15 {padding-bottom:15px !important;}
.pb20 {padding-bottom:20px !important;}
.pb25 {padding-bottom:25px !important;}

#loader {
	z-index: 9999;
}

.loaded #loader {
	opacity: 0;
}

/* JavaScript Turned Off */
.no-js #loader-wrapper {
	display: none;
}

.loaded #loader-wrapper {
	visibility: hidden;
}

#loader-wrapper {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1000;
}
#loader {
	display: block;
	position: relative;
	left: 50%;
	top: 50%;
	width: 150px;
	height: 150px;
	margin: -75px 0 0 -75px;
	border-radius: 50%;
	border: 3px solid transparent;
	border-top-color: #939598;

	-webkit-animation: spin 2s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
	animation: spin 2s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */

	z-index: 1001;
}

#loader:before {
	content: "";
	position: absolute;
	top: 5px;
	left: 5px;
	right: 5px;
	bottom: 5px;
	border-radius: 50%;
	border: 3px solid transparent;
	border-top-color: #8ec63e;

	-webkit-animation: spin 3s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
	animation: spin 3s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
}

#loader:after {
	content: "";
	position: absolute;
	top: 15px;
	left: 15px;
	right: 15px;
	bottom: 15px;
	border-radius: 50%;
	border: 3px solid transparent;
	border-top-color: #f2c715;

	-webkit-animation: spin 1.5s linear infinite; /* Chrome, Opera 15+, Safari 5+ */
	animation: spin 1.5s linear infinite; /* Chrome, Firefox 16+, IE 10+, Opera */
}

@-webkit-keyframes spin {
	0%   { 
		-webkit-transform: rotate(0deg);  /* Chrome, Opera 15+, Safari 3.1+ */
		-ms-transform: rotate(0deg);  /* IE 9 */
		transform: rotate(0deg);  /* Firefox 16+, IE 10+, Opera */
	}
	100% {
		-webkit-transform: rotate(360deg);  /* Chrome, Opera 15+, Safari 3.1+ */
		-ms-transform: rotate(360deg);  /* IE 9 */
		transform: rotate(360deg);  /* Firefox 16+, IE 10+, Opera */
	}
}
@keyframes spin {
	0%   { 
		-webkit-transform: rotate(0deg);  /* Chrome, Opera 15+, Safari 3.1+ */
		-ms-transform: rotate(0deg);  /* IE 9 */
		transform: rotate(0deg);  /* Firefox 16+, IE 10+, Opera */
	}
	100% {
		-webkit-transform: rotate(360deg);  /* Chrome, Opera 15+, Safari 3.1+ */
		-ms-transform: rotate(360deg);  /* IE 9 */
		transform: rotate(360deg);  /* Firefox 16+, IE 10+, Opera */
	}
}

#loader-wrapper .loader-section {
	position: fixed;
	top: 0;
	width: 51%;
	height: 100%;
	background: #222222;
	z-index: 1000;
	-webkit-transform: translateX(0);  /* Chrome, Opera 15+, Safari 3.1+ */
	-ms-transform: translateX(0);  /* IE 9 */
	transform: translateX(0);  /* Firefox 16+, IE 10+, Opera */
}

#loader-wrapper .loader-section.section-left {
	left: 0;
}

#loader-wrapper .loader-section.section-right {
	right: 0;
}

/* Loaded */
.loaded #loader-wrapper .loader-section.section-left {
	-webkit-transform: translateX(-100%);  /* Chrome, Opera 15+, Safari 3.1+ */
		-ms-transform: translateX(-100%);  /* IE 9 */
			transform: translateX(-100%);  /* Firefox 16+, IE 10+, Opera */

	-webkit-transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1.000);  
			transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1.000);
}

.loaded #loader-wrapper .loader-section.section-right {
	-webkit-transform: translateX(100%);  /* Chrome, Opera 15+, Safari 3.1+ */
		-ms-transform: translateX(100%);  /* IE 9 */
			transform: translateX(100%);  /* Firefox 16+, IE 10+, Opera */

-webkit-transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1.000);  
	transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1.000);
}

.loaded #loader {
	opacity: 0;
	-webkit-transition: all 0.3s ease-out;  
			transition: all 0.3s ease-out;
}
.loaded #loader-wrapper {
	visibility: hidden;

	-webkit-transform: translateY(-100%);  /* Chrome, Opera 15+, Safari 3.1+ */
		-ms-transform: translateY(-100%);  /* IE 9 */
			transform: translateY(-100%);  /* Firefox 16+, IE 10+, Opera */

	-webkit-transition: all 0.3s 1s ease-out;  
			transition: all 0.3s 1s ease-out;
}