@font-face {
	font-family: 'Nunito Sans';
	src: url('../fonts/NunitoSans-SemiBold.woff2') format('woff2'),
		url('../fonts/NunitoSans-SemiBold.woff') format('woff'),
		url('../fonts/NunitoSans-SemiBold.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Muli';
	src: url('../fonts/Muli-Regular.woff2') format('woff2'),
		url('../fonts/Muli-Regular.woff') format('woff'),
		url('../fonts/Muli-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Muli';
	src: url('../fonts/Muli-SemiBold.woff2') format('woff2'),
		url('../fonts/Muli-SemiBold.woff') format('woff'),
		url('../fonts/Muli-SemiBold.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Nunito Sans';
	src: url('../fonts/NunitoSans-Regular.woff2') format('woff2'),
		url('../fonts/NunitoSans-Regular.woff') format('woff'),
		url('../fonts/NunitoSans-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Nunito Sans';
	src: url('../fonts/NunitoSans-Bold.woff2') format('woff2'),
		url('../fonts/NunitoSans-Bold.woff') format('woff'),
		url('../fonts/NunitoSans-Bold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Muli';
	src: url('../fonts/Muli-Bold.woff2') format('woff2'),
		url('../fonts/Muli-Bold.woff') format('woff'),
		url('../fonts/Muli-Bold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
}



