.slider {
	&__inner {
	}

	&--overlay {
		background-color: rgba(0,0,0,.5);
	}

	&__overlay {
		width:100%;
		height:100%;
		display:block;
		position: absolute;
		background-size: cover;
		background-position: center center;
	}

	&__video {
		z-index: 2;
		width:100%;
		height:100%;
		display:block;
		position: absolute;
		//padding-bottom: 1%;
		//.video {
			//padding-bottom: 83%!important;
		//}
		//.inline-YTPlayer {
		//	height: 100%;
		//}
		&__iframe {
			position: relative;
			height: 100%;
			.mbYTP_wrapper {
			padding-bottom: 56.25%;
			}
		}
	}

	&__content {
		height: 100vh;
		z-index: 4;
		&__item {
			display: none;
			height: 100vh;
			&__background {
				height: 100%;
				background-size: cover;
			}
			&__inner {
				height: 100%;
				.verticalcentered();
				text-align: center;
				h1 {
					font-size: @font-size-h1 + 10px;
					color: @white;
					margin-bottom: 30px;
				}
				p {
					font-size: @font-size-base + 2px;
					color: @white;
				}
				a {
					color: @white;
				}

				&--h2 {
					p {
						font-weight: bold;
						font-size: @font-size-h2;
					}
				}
			}
		}
	}

	&--h200 {
		height: 200px;
		display: block;
	}
}