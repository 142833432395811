.content {
	overflow: hidden;
	position: relative;
	&__header {
		text-align: center;
		padding-bottom: 100px;
		padding-top: 180px;
		&--background {
			background-size: cover;
			background-position: center center;
			h1 {
				color: @white;
			}
		}
	}
	&__body {
		position: relative;
	}
	&__inner {
		padding: 30px 0px;
		&--vertical-center {
			width: 100%;
			.vertical-align();
			top: 60%;
		}
	}
	&__actions {
		text-align: center;
		margin: 50px 0px 30px 0px;
	}
	&--p250 {
		padding: 250px 0px;
		@media (max-width: @screen-sm-max) {
			padding: 50px 0px;
		}
	}
}

.text {
	&__inner {
		padding: 60px 0;
	}
}

.aboutUs {
	color: @text-color;
	h1, h2, h3 {
		font-weight: 500;
		color: @text-color;
		margin-top: 0px;
		text-shadow: 0px 4px 3px rgba(160,160,160,.7);
	}
	ul {
		list-style-image: url('data:image/svg+xml,%3Csvg%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%20512%20512%22%3E%3Cstyle%3E.st0%7Bfill%3A%23333%7D%3C%2Fstyle%3E%3Cpath%20class%3D%22st0%22%20d%3D%22M0%200v512l441-256z%22%2F%3E%3C%2Fsvg%3E');
	}
}

#o-firme {
	position: relative;
	&:before {
		z-index: 20;
		content: " ";
		left: 0;
		right: 0;
		top: -330px;
		height: 330px;
		position: absolute;
		background-image:url('../images/background_top.png');
		background-repeat: no-repeat;
		background-position: center center;
		background-size: 100% 330px;
	}
	&:after {
		z-index: 20;
		content: " ";
		left: 0;
		right: 0;
		height: 150px;
		bottom: -150px;
		position: absolute;
		background-image:url('../images/background_bottom.png');
		background-repeat: no-repeat;
		background-position: center center;
		background-size: 100% 150px;
	}
	.content {
		background-image:url('../images/background.png');
		background-repeat:y-repeat;
		background-position:center center;
		background-size: 100% 9px;
		position: relative;
		z-index: 800;
	}
}

#vybaveni {
	background-image:url('../images/forest_worker.png');
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
}

.video {
	position: absolute;
	z-index: 0;
	width: 100%;
	height: 100%;
	top: 0px;
	left: 0px;
}