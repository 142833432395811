.contact {
	position: relative;
	&__content {
		padding: 200px 0 60px;
		background-color: @white;
		&__inner {
			color: @text-color;
			padding: 15px 30px;
		}
	}
	&:before {
		content: " ";
		z-index: 700;
		background-image: url('../images/mapa_top_shape.png');
		background-repeat: no-repeat;
		background-position: bottom center;
		background-size: cover;
		top: 0;
		left: 0;
		right: 0;
		position: absolute;
		height: 400px;
	}
}

.teams {
	padding-bottom: 30px;
	border-bottom: 1px solid @white;
	margin-bottom: 30px;
	&__items {
		text-align: center;
	}
	&__item {
		display: inline-block;
		text-align: center;
		border: 2px solid @white;
		border-radius: 50%;
		color: @white;
		width: 200px;
		height: 200px;
		padding: 15px;
		margin-bottom: 20px;
		&__name {

		}
		&__number {
			padding: 25px 0px;
			font-weight: bold;
			font-size: @font-size-base + 8px;
		}
		@media (max-width: @screen-xs-max) {

		}
		@media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {

		}
	}
}

.equipments {
	&__item {
		position: relative;
		display: block;
		text-align: center;
		border: 2px dotted @white;
		height: 150px;
		line-height: 150px;
		//padding: 50px 0px;
		color: @white;
		.transition(background-color .5s ease);
		margin-bottom: 20px;
		background-repeat: no-repeat;
		background-position: center center;
		span {
			display: inline-block;
			vertical-align: middle;
			line-height: normal;
			position: relative;
			z-index: 10;
		}
		&:hover {
			background-color: rgba(255,255,255,0.7);
			&:after {
				background-color: rgba(86, 85, 26, 0.5);
			}
		}
		&:after {
			content: " ";
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-color: rgba(0,0,0,0.5);
		}
	}
}

.gallery {
	&__image {
		margin-top: 30px;
		margin-bottom: 30px;
		a {
			display: block;
		}
	}
}

.references {
	position: relative;
	&__items {
		&__item {
			a {
				background-color: @white;
				display: block;
				position: relative;
				span {
					position: absolute;
					background-color: rgba(0,0,0,0.3);
					top: 0;
					bottom: 0;
					left: 0;
					right: 0;
					.transition(background-color .5s ease);
				}
				&:hover {
					span {
						background-color: rgba(86, 85, 26, 0.5);
					}
				}
			}
		}
	}
	&__arrows {
		position: absolute;
		top: 5px;
		bottom: 5px;
		z-index: 1000;
		width: 10px;
		background-color: rgba(255,255,255,0.7);
		padding: 10px;
		text-align: center;
		cursor: pointer;
		&--left {
			left: 0px;
			i {
				left: 3px;
			}
		}
		&--right {
			right :0px;
			i {
				right: 3px;
			}
		}
		i {
			.vertical-align();
		}
	}
}