header {
	position: fixed;
	display:block;
	width:100%;
	top: 0px;
	z-index: 1000;
	&.header-scrolled {
		//background-color: @white;
		transition: background-color 2s;
		a {
			color: @text-color !important;
		}
	}
	.top {
		background-image: url('../images/top_menu_bkg.png');
		background-repeat: no-repeat;
		background-size: cover;
		background-position: top center;
		padding:15px 0px;
		&__container {
			.container();
			text-align: center;


			#logo {
				padding: 4px 0px;
				display: inline-block;
				img {
					display: inline-block;
				}
				@media (max-width: @screen-xs-max) {
					width: auto;
					display: inline-block;
				}
			}

			ul {
				//float: right;
				.resetoffset();
				position: relative;
				list-style: none;
				li {
					display: inline-block;
					a {
						transition: color 1s;
						display: block;
						padding: 5px 0px;
						font-size: @font-size-base - 1px;
						font-weight: bold;
						color: @brand-third;
						text-transform: uppercase;
						&.socials {
							padding: 5px 5px;
							display: inline-block;
						}
						&:hover {
							text-decoration: none;
						}
						&:after {
							content: '';
							display: block;
							margin: auto;
							height: 1px;
							width: 0px;
							background: transparent;
							.transition(~"width .5s ease, background-color .5s ease");
						}
						&:hover:after {
							width: 100%;
							background: @brand-third;
						}
					}
					&.right {
						float: right;
					}
					&.active {
						a {
							&:after {
								content: '';
								display: block;
								margin: auto;
								height: 1px;
								width: 100%;
								background: @brand-second;
							}
						}
					}
				}
				li + li {
					margin-left: 30px;
				}
				@media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
					li + li {
						margin-left: 15px;
					}
				}
				@media (max-width: @screen-xs-max) {
					margin-top: 20px;
					li + li {
						margin-left: 0px;
					}
					li {
						display: block;
						margin-bottom: 10px;
						&.right {
							float: none;
						}
					}
				}
			}
		}
	}
}