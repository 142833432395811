//
// Buttons
// --------------------------------------------------
.svg-button,.svg-button-before:before,.svg-button-after:after{background-image:url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2076.32%2023.96%22%3E%3Cdefs%3E%3Cstyle%3E.a%7Bfill%3A%23f28d23%7D.b%7Bfill%3Anone%3Bstroke%3A%23f28d23%3Bstroke-miterlimit%3A10%3Bstroke-width%3A.5px%7D%3C%2Fstyle%3E%3C%2Fdefs%3E%3Ctitle%3EDatov%C3%BD%20zdroj%202%3C%2Ftitle%3E%3Cpath%20class%3D%22a%22%20d%3D%22M.87%200h68.38a4.45%204.45%200%200%201%204.45%204.45v16a1%201%200%200%201-1%201H3.91A3.91%203.91%200%200%201%200%2017.48V.87A.87.87%200%200%201%20.87%200z%22%2F%3E%3Cpath%20class%3D%22b%22%20d%3D%22M4.34%201.87h66.52a3.92%203.92%200%200%201%203.92%203.92v16.7H7.12a3.38%203.38%200%200%201-3.39-3.39V2.48a.61.61%200%200%201%20.61-.61z%22%2F%3E%3Cpath%20class%3D%22b%22%20d%3D%22M5.63%203.09h66.51A3.92%203.92%200%200%201%2076.07%207v16.71H8.4A3.38%203.38%200%200%201%205%2020.33V3.7a.61.61%200%200%201%20.63-.61z%22%2F%3E%3C%2Fsvg%3E')};

// Base styles
// --------------------------------------------------

.btn {
  position: relative;
  display: inline-block;
  margin-bottom: 0; // For input.btn
  font-weight: @btn-font-weight;
  text-align: center;
  touch-action: manipulation;
  cursor: pointer;
  border: 1px solid transparent;
  white-space: nowrap;
  font-size: @font-size-base;
  padding: 10px 34px;
  border-top-right-radius: 12px;
  border-bottom-left-radius: 12px;
  .transition(all .5s);
  //.button-size(@padding-base-vertical; @padding-base-horizontal; @font-size-base; @line-height-base; @btn-border-radius-base);
  .user-select(none);

  &,
  &:active,
  &.active {
    &:focus,
    &.focus {
      .tab-focus();
    }
  }

  &:hover,
  &:focus,
  &.focus {
    color: @btn-default-color;
    text-decoration: none;
  }

  &:active,
  &.active {
    outline: 0;
    background-image: none;
    .box-shadow(inset 0 3px 5px rgba(0,0,0,.125));
  }

  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    cursor: @cursor-disabled;
    .opacity(.65);
    .box-shadow(none);
  }

  a& {
    &.disabled,
    fieldset[disabled] & {
      pointer-events: none; // Future-proof disabling of clicks on `<a>` elements
    }
  }
}


// Alternate buttons
// --------------------------------------------------

.btn-default {
  color: @black;
  background-color: transparent;
  border: 1px solid @black;
  box-shadow: 3px 3px 0px -1px @white, 
  3px 3px 0 0 @black, 
  6px 6px 0 -1px @white, 
  6px 6px 0 0 @black;
  &:hover {
    background-color: @black;
    color: @white;
  }
}
.btn-primary {
  color: #FFFFFF;
  background-color: @brand-primary;
  border: 1px solid @brand-primary;
  box-shadow: 3px 3px 0px -1px @white, 
  3px 3px 0 0 @brand-primary, 
  6px 6px 0 -1px @white, 
  6px 6px 0 0 @brand-primary;
  &:hover {
    background-color: @white;
    color: @brand-primary;
  }
}
// Success appears as green
.btn-success {
  .button-variant(@btn-success-color; @btn-success-bg; @btn-success-border);
}
// Info appears as blue-green
.btn-info {
  .button-variant(@btn-info-color; @btn-info-bg; @btn-info-border);
}
// Warning appears as orange
.btn-warning {
  .button-variant(@btn-warning-color; @btn-warning-bg; @btn-warning-border);
}
// Danger and error appear as red
.btn-danger {
  .button-variant(@btn-danger-color; @btn-danger-bg; @btn-danger-border);
}


// Link buttons
// -------------------------

// Make a button look and behave like a link
.btn-link {
  color: @link-color;
  font-weight: normal;
  border-radius: 0;

  &,
  &:active,
  &.active,
  &[disabled],
  fieldset[disabled] & {
    background-color: transparent;
    .box-shadow(none);
  }
  &,
  &:hover,
  &:focus,
  &:active {
    border-color: transparent;
  }
  &:hover,
  &:focus {
    color: @link-hover-color;
    text-decoration: @link-hover-decoration;
    background-color: transparent;
  }
  &[disabled],
  fieldset[disabled] & {
    &:hover,
    &:focus {
      color: @btn-link-disabled-color;
      text-decoration: none;
    }
  }
}


// Button Sizes
// --------------------------------------------------

.btn-lg {
  // line-height: ensure even-numbered height of button next to large input
  .button-size(@padding-large-vertical; @padding-large-horizontal; @font-size-large; @line-height-large; @btn-border-radius-large);
}
.btn-sm {
  // line-height: ensure proper height of button next to small input
  .button-size(@padding-small-vertical; @padding-small-horizontal; @font-size-small; @line-height-small; @btn-border-radius-small);
}
.btn-xs {
  .button-size(@padding-xs-vertical; @padding-xs-horizontal; @font-size-small; @line-height-small; @btn-border-radius-small);
}


// Block button
// --------------------------------------------------

.btn-block {
  display: block;
  width: 100%;
}

// Vertically space out multiple block buttons
.btn-block + .btn-block {
  margin-top: 5px;
}

// Specificity overrides
input[type="submit"],
input[type="reset"],
input[type="button"] {
  &.btn-block {
    width: 100%;
  }
}
